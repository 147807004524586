// extracted by mini-css-extract-plugin
export var mePage = "me-module--mePage--2rZOE";
export var topImage = "me-module--topImage--3mRIW";
export var itemTitle = "me-module--itemTitle--3HKcK";
export var jobContentSmall = "me-module--jobContentSmall--14Okh";
export var introduction = "me-module--introduction--3bu0N";
export var historyList = "me-module--historyList--3KXQv";
export var companyItem = "me-module--companyItem--_pAVB";
export var companyItem2 = "me-module--companyItem2--1GIgb";
export var primaryWord = "me-module--primaryWord--2573Z";
export var linkText = "me-module--linkText--2xiJv";